<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="title"
    width="400px"
    :close-on-click-modal="false"
    @close="cancel"
  >
    <el-form :model="formData" ref="form">
      <el-form-item prop="data" label="" :rules="[rules.required()]">
        <f-upload
          ref="uploader"
          :type="5"
          :limitSize="5"
          :isCompress="false"
          accept=".pdf, .doc, .docx, .xls, .xlsx,.jpg,.jpeg,.png,.gif,.PDF,.DOC,.DOCX,.XLS,.XLSX,.JPG,.JPEG,.PNG,.GIF"
          :default-file-list="fileList"
          :showProcess="true"
          @on-success="fileList => uploadSuccess(fileList)"
        ></f-upload>
        <div class="mt15" v-for="file in formData.data" :key="file.id">
          <file-item :options="file" :isEdit="file.isEdit">
            <edit-file
              v-if="file.isEdit"
              :title.sync="curItem.title"
              @close="curItem.isEdit = false"
            ></edit-file>
            <template v-else>
              <div class="percent">
                <span v-if="file.percent < 100">{{ file.percent }}%</span>
                <i class="el-icon-circle-check success" v-else></i>
              </div>
              <div class="action">
                <i class="el-icon-edit-outline" @click="editItem(file)"></i>
                <i class="el-icon-delete" @click="removeItem(file)"></i>
              </div>
            </template>
          </file-item>
          <div class="progress">
            <el-progress
              :percentage="file.percent"
              :show-text="false"
              :stroke-width="4"
              v-if="file.percent < 100"
            ></el-progress>
          </div>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">提 交</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import { docSize, docType } from "../../_js/index";
import FUpload from "@/components/upload";
import editFile from "./_components/editFile";
import fileItem from "../fileItem";
import { createProjectData } from "@/api/distribution";
export default {
  components: { FUpload, fileItem, editFile },
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      rules,
      title: "添加资料",
      formData: {
        data: []
      },
      estateId: null,
      fileList: [],
      curItem: {}
    };
  },
  created() {
    this.estateId = this.$store.state.estateInfo.estateId;
  },
  methods: {
    docSize,
    docType,
    async uploadSuccess(fileList) {
      // 上传成功的回调，处理文件信息
      this.fileList = fileList;
      let arr = fileList.map(item => {
        let { title, docType } = this.docType(item.name);
        let { size, unit } = this.docSize(item.size);
        return {
          uid: item.uid,
          estateId: this.estateId,
          type: docType == "image" ? 1 : 2, // 1: 图片, 2: 文档
          url: item.url,
          title,
          docType,
          size,
          unit,
          isEdit: false,
          percent: item.percent
        };
      });
      this.formData.data = arr;
    },
    editItem(file) {
      // 编辑文件信息
      this.formData.data.forEach(item => (item.isEdit = false));
      this.curItem = this.formData.data.find(item => item.uid == file.uid);
      this.curItem.isEdit = true;
    },
    removeItem(file) {
      // 删除上传列表中文件
      this.fileList = this.fileList.filter(item => item.url != file.url);
      // 删除显示列表中的文件
      this.formData.data = this.formData.data.filter(
        item => item.url != file.url
      );
    },
    cancel() {
      this.$refs.form.resetFields();
      this.$emit("update:visible", false);
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (!valid) return;
        const res = await createProjectData(this.formData);
        if (res) {
          this.$showSuccess("添加成功");
          this.cancel();
          this.$emit("refresh");
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
.item-file {
  padding: 10px 0 10px 8px;
  &:hover {
    background-color: #f5f7fa;
    border-radius: 5px;
    .action {
      display: block;
    }
    .percent {
      display: none;
    }
  }

  .action {
    display: none;
    i {
      font-size: 18px;
      color: #aaa;
      padding: 0 10px;
      cursor: pointer;
      &:hover {
        color: #888;
      }
    }
  }
  .percent {
    margin-right: 8px;
    i {
      font-size: 20px;
    }
  }
}
.progress {
  height: 4px;
  padding: 0 8px;
}
</style>
